import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currentRouteKey: "",
  currentReport: "",
  onboard: localStorage.getItem("onboard") || false,
};

export const commonSlice = createSlice({
  name: "base/common",
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload;
    },
    setCurrentReport: (state, action) => {
      state.currentReport = action.payload;
    },
    setOnboard: (state, action) => {
      state.onboard = action.payload;
    },
  },
});

export const { setCurrentRouteKey, setCurrentReport, setOnboard } =
  commonSlice.actions;

export default commonSlice.reducer;
