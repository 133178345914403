import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { Notification, toast } from "components/ui";
import { REDIRECT_URL_KEY } from "constants/app.constant";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const { token, signedIn } = useSelector((state) => state.auth.session);
  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        const { token } = resp.data.data;
        dispatch(onSignInSuccess(token));
        if (resp.data) {
          dispatch(
            setUser(
              {
                avatar: "",
                userName: resp.data.data.fullname,
                phone: resp.data.data.phone,
                companies: resp.data.data.companies || [],
                authority: resp.data.data.roles,
                branches: resp.data.data.branches.map((item) => item.branch),
                isindividual: resp.data.data.isindividual,
                email: resp.data.data.email,
                status: resp.data.data.status,
              } || {
                avatar: "",
                userName: "Anonymous",
                phone: "",
                authority: ["USER"],
                branches: [],
                isindividual: true,
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signInForUser = (resp) => {
    if (resp.data) {
      const { token } = resp.data.data;
      dispatch(onSignInSuccess(token));
      if (resp.data) {
        dispatch(
          setUser(
            {
              avatar: "",
              userName: resp.data.data.fullname,
              phone: resp.data.data.phone,
              companies: resp.data.data.companies || [],
              authority: resp.data.data.roles,
              branches: resp.data.data.branches.map((item) => item.branch),
              isindividual: resp.data.data.isindividual,
              email: resp.data.data.email,
              status: resp.data.data.status,
            } || {
              avatar: "",
              userName: "Anonymous",
              phone: "",
              authority: ["USER"],
              branches: [],
              isindividual: true,
              email: "",
            }
          )
        );
      }
      const redirectUrl = query.get(REDIRECT_URL_KEY);
      navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
      return {
        status: "success",
        message: "",
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data.succeeded) {
        return {
          data: resp.data.data,
          status: "success",
        };
        // navigate("sign-in");
        // toast.push(
        //   <Notification
        //     title="Başarıyla Kayıt Oldunuz!"
        //     type="success"
        //     duration={3500}
        //     width={500}
        //   >
        //     Lütfen giriş yapınız.
        //   </Notification>,
        //   {
        //     placement: "top-center",
        //   }
        // );
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    window.HubSpotConversations.clear({ resetWidget: true });
    navigate("/sign-in");
  };

  const signOut = async () => {
    // There is no signout api right now.
    // await apiSignOut();
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signInForUser,
    signUp,
    signOut,
  };
}

export default useAuth;
